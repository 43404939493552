// -----------------------------------------------------------------------------
// Définition des polices utilitées
// -----------------------------------------------------------------------------

@use '@fontsource/montserrat/scss/mixins' as Montserrat;
@use '@fontsource/oswald/scss/mixins' as Oswald;

@each $font-weight in $font-weights {
  @include Montserrat.fontFace(
    $fontName: $font-family-sans-serif,
    $weight: $font-weight
  );
  @include Montserrat.fontFace(
    $fontName: $font-family-sans-serif,
    $weight: $font-weight,
    $style: italic
  );
}

@each $font-weight in $headings-font-weights {
  @include Oswald.fontFace(
    $fontName: $headings-font-family,
    $weight: $font-weight
  );
}
