// -----------------------------------------------------------------------------
// Étends les styles du carrousel
// -----------------------------------------------------------------------------
// L'affichage du contenu d'un élément de carrousel bascule à partir de `md`,
// ainsi que les contrôles, qui passent des boutons aux indicateurs.

$carousel-border-color: rgba($black, 0.2);

$carousel-item-image-width-md: 62%;
$carousel-item-image-height: 290px;
$carousel-item-image-height-lg: 400px;

$carousel-item-image-separator-width: 40px;
$carousel-item-image-separator-skew: 10deg;

.carousel-inner,
.carousel-item,
.carousel-item-content {
  height: 100%;
}

.carousel-indicators {
  [data-bs-target] {
    border-radius: 100%;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  align-items: flex-end;
  padding-bottom: 1.25rem;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

// Contenu d'un élément de carrousel, pouvant contenir .carousel-item-image
// suivi de .carousel-item-text
.carousel-item-content {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  > * {
    position: relative;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
  }

  &.has-image {
    .carousel-item-text {
      margin-top: -56px;
    }
  }
}

.carousel-item-image {
  height: $carousel-item-image-height;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.carousel-item-text {
  width: 100% - $carousel-control-width * 2;
  padding: 1rem;
  text-align: center;
  background-color: $white;
}

@include media-breakpoint-up(md) {
  .carousel {
    // Ajoute une bordure intérieure en haut et en bas
    &::before,
    &::after {
      position: absolute;
      z-index: 10;
      width: 100%;
      content: '';
      border-top: 1px solid $carousel-border-color;
    }
    &::after {
      bottom: 0;
    }
  }

  .carousel-item-content {
    &.has-image {
      // Ajoute une bordure intérieure à droite s'il y a une image
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
        height: 100%;
        content: '';
        border-left: 1px solid $carousel-border-color;
      }

      .carousel-item-text {
        margin-top: 0;
      }
    }
  }

  .carousel-item-image {
    flex: 0 0 auto;
    width: $carousel-item-image-width-md;

    // Barres verticales obliques séparants le texte
    &::before,
    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      content: '';
      transform: skewX(#{$carousel-item-image-separator-skew});
      transform-origin: top left;
    }
    &::before {
      left: -1000px;
      width: $carousel-item-image-separator-width + 999px;
      background-color: $white;
    }
    &::after {
      box-sizing: content-box;
      width: $carousel-item-image-separator-width * 0.275;
      border-right: $carousel-item-image-separator-width * 0.275 solid $blue;
      border-left: $carousel-item-image-separator-width * 0.45 solid $blue;
    }
  }

  .carousel-item-text {
    flex: 1 0 0%;
    order: -1;
    padding: 2rem 1rem;
    text-align: left;
  }
}

@include media-breakpoint-up(lg) {
  .carousel-item-image {
    height: $carousel-item-image-height-lg;
  }
}
