// -----------------------------------------------------------------------------
// Classes d'utilitaires utilisées dans le site
// -----------------------------------------------------------------------------

.title {
  color: $primary;
}

h1 {
  display: inline-block;
  &::after {
    display: block;
    width: 75%;
    height: 0.125em;
    margin-top: 0.5rem;
    content: '';
    background-color: currentColor;

    .text-center & {
      margin-right: auto;
      margin-left: auto;
    }

    .text-end & {
      margin-left: auto;
    }
  }
}

h2 {
  width: fit-content;
  padding-bottom: 0.5rem;
  border-bottom: solid 0.125em;
}

.link-black {
  color: $black;

  &:hover,
  &:focus {
    color: tint-color($black, $link-shade-percentage);
  }
}

.text-white {
  a:not(.btn),
  .figure-caption,
  .title {
    color: $white;
  }
}

.link-underline a:not(.btn) {
  text-decoration: underline;
}
