@include media-breakpoint-up(md) {
  .title {
    scroll-margin-top: var(--psm-menu-height); // compense la hauteur du menu
  }
}

p > a {
  font-weight: $font-weight-bold;
}

main {
  p,
  ul,
  ol {
    text-align: justify;
  }
}
