// -----------------------------------------------------------------------------
// Variables du site
// -----------------------------------------------------------------------------

//
// Bootstrap
//
// Pour les variables disponibles et leur valeur par défaut, voir :
// ../../node_modules/bootstrap/scss/_variables.scss

// Color system

$white: #fff;
$light: #eceeef;
$gray: #adb4b8;
$dark: #374955;
$black: #000;

$blue: #3883ae;
$green: #498231;
$yellow: #f2a726;

$primary: #286688;
$success: #74b334;
$danger: #bc5f5d;

$theme-colors: (
  'primary': $primary,
  'success': $success,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  'blue': $blue,
  'green': $green,
  'yellow': $yellow,
);

$min-contrast-ratio: 3;

// Options

$enable-validation-icons: false;

// Body

$body-color: $black;

// Links

$link-decoration: none;

// Components

$border-radius: 0.25rem;

$box-shadow: 0 0.5rem 1rem rgba($black, 0.15);
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075);
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175);
$box-shadow-inset: inset 0 1px 2px rgba($black, 0.075);

// Typography

$font-family-sans-serif: 'Montserrat';

$font-size-base: 1.125rem;
$font-size-sm: 1rem;
$font-size-lg: 1.25rem;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$font-weights: $font-weight-light, $font-weight-normal, $font-weight-medium,
  $font-weight-bold;

$h1-font-size: 3rem;
$h2-font-size: 2.5rem;
$h3-font-size: 2rem;
$h4-font-size: 1.75rem;
$h5-font-size: 1.5rem;
$h6-font-size: $font-size-base;

$headings-margin-bottom: 0.875rem;
$headings-font-family: 'Oswald';
$headings-font-weight: $font-weight-normal;

$headings-font-weights: $font-weight-normal, $font-weight-bold;

$lead-font-size: 1.375rem;
$lead-font-weight: $font-weight-medium;

$small-font-size: $font-size-sm;

$text-muted: rgba($black, 0.75);

// Buttons

$btn-padding-y: 0.375rem;
$btn-padding-x: 0.875rem;

$btn-font-weight: $font-weight-medium;

// Navbar

$navbar-padding-y: 0;

$navbar-color: $primary;
$navbar-hover-color: $dark;
$navbar-active-color: $black;
$navbar-disabled-color: rgba($primary, 0.3);

$navbar-nav-link-padding-x: 2rem;

$navbar-toggler-padding-y: 0.25rem;
$navbar-toggler-padding-x: 0.25rem;
$navbar-toggler-font-size: 2rem;
$navbar-toggler-border-radius: 0;
$navbar-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$black}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");

// Dropdown

$dropdown-spacer: 0;
$dropdown-bg: $dark;
$dropdown-border-width: 0;
$dropdown-divider-bg: rgba($white, 0.25);

$dropdown-link-color: $white;
$dropdown-link-hover-color: rgba($white, 0.75);
$dropdown-link-hover-bg: transparent;

$dropdown-link-active-color: $white;
$dropdown-link-active-bg: rgba($black, 0.15);

$dropdown-header-color: rgba($white, 0.85);

// Cards

$card-border-width: 0;
$card-border-radius: 0;
$card-bg: mix($white, $light);
$card-cap-padding-y: 0.75rem;
$card-cap-bg: transparent;

// Popovers

$popover-font-size: 0.875rem;
$popover-box-shadow: $box-shadow-sm;

// Alerts

$alert-bg-scale: -60%;
$alert-border-scale: -50%;
$alert-color-scale: 80%;

// Carousel

$carousel-control-color: $black;
$carousel-control-width: 12%;
$carousel-control-opacity: 0.7;
$carousel-control-hover-opacity: 1;

$carousel-indicator-width: 14px;
$carousel-indicator-height: 14px;
$carousel-indicator-hit-area-height: 8px;
$carousel-indicator-spacer: 4px;
$carousel-indicator-opacity: 0.4;
$carousel-indicator-active-bg: $yellow;

// Close

$btn-close-opacity: 0.7;
$btn-close-hover-opacity: 1;
$btn-close-focus-opacity: 1;
