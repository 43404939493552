// -----------------------------------------------------------------------------
// Définition des styles pour les icônes du site
// -----------------------------------------------------------------------------

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -0.125em;
  fill: currentColor;
}

@for $size from 1 through 5 {
  .icon-x#{$size} {
    width: $size * 1em;
    height: $size * 1em;
  }
}
