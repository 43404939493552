// -----------------------------------------------------------------------------
// Bouton pour aller en haut de page
// -----------------------------------------------------------------------------

$scroll-top-btn-width: 38px;

.scroll-top {
  position: absolute;
  top: 100vh;
  right: 1rem;
  bottom: -2rem;
  width: $scroll-top-btn-width;
  pointer-events: none;
}

.scroll-top-btn {
  @include button-variant($black, $black, $white);

  position: fixed;
  position: sticky;
  top: calc(100vh - #{$scroll-top-btn-width} - 1rem);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: $scroll-top-btn-width;
  height: $scroll-top-btn-width;
  pointer-events: auto;
  user-select: none;
  border-radius: $btn-border-radius;
}
