// -----------------------------------------------------------------------------
// Étends les styles des tuiles
// -----------------------------------------------------------------------------

.card {
  .stretched-link {
    outline: none;

    &::after {
      transition: box-shadow 0.15s ease-in-out;
    }

    &:hover,
    &:active {
      &::after {
        box-shadow: $box-shadow-sm;
      }
    }

    &:focus {
      &::after {
        box-shadow: 0 0 0 $btn-focus-width rgba($primary, 0.5);
      }
    }
  }
}

.card-footer {
  padding-top: 0;

  &::before {
    display: block;
    width: 100%;
    margin-bottom: $card-cap-padding-y;
    content: '';
    border-top: 1px solid $card-border-color;
  }
}

.card-reduce {
  @include media-breakpoint-up(md) {
    max-width: 75%;
  }
  @include media-breakpoint-up(lg) {
    max-width: 50%;
  }
}
