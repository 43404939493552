// -----------------------------------------------------------------------------
// Étends les styles de la barre de navigation
// -----------------------------------------------------------------------------

.navbar {
  background-color: $white;
  border-bottom: 1px solid rgba($light, 0);
  transition: border-bottom 0.1s ease-in-out;

  &.sticky {
    border-bottom-color: rgba($light, 1);

    .navbar-icon {
      display: flex;
    }
  }
}

.navbar-nav {
  align-items: center;

  .nav-link {
    @include font-size(24px);

    position: relative;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-family: $headings-font-family;
    color: var(--nav-item-color, #{$navbar-color});
    text-align: center;
    text-transform: uppercase;
    outline: none;

    &:hover,
    &:focus {
      color: $navbar-hover-color;
    }

    &.disabled {
      color: $navbar-disabled-color;
    }

    &.active {
      color: $navbar-active-color;
    }

    &.has-submenu {
      min-width: $dropdown-min-width;
      color: $primary;
      text-align: center;

      &.active {
        color: var(--nav-item-color, #{$navbar-active-color});
      }

      &.show {
        color: $white;

        &::before {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          content: '';
          background-color: var(--nav-item-color, #{$primary});
          transform: skewX(-10deg);
          transform-origin: bottom left;
        }
      }

      > span {
        position: relative;
      }
    }
  }

  .dropdown-menu {
    right: 0;
    max-width: 100%;
    color: $white;
    text-align: center;
    background-color: var(--nav-item-color, #{$primary});
    border-radius: 0 0 $border-radius $border-radius;
  }

  .dropdown-item {
    white-space: normal;
  }
}

.nav-item {
  @each $color, $value in $theme-colors {
    &.is-#{$color} {
      --nav-item-color: #{$value};
    }
  }
}

.navbar-toggler-icon {
  background-image: escape-svg($navbar-toggler-icon-bg);
}

.navbar-toggler-text {
  display: block;
  font-size: 0.75rem;
  font-weight: $font-weight-medium;
  text-transform: uppercase;
}

.navbar-icon {
  position: absolute;
  top: 0;
  right: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0.375rem 0;

  > img {
    width: auto;
    max-height: 100%;
  }
}
