// -----------------------------------------------------------------------------
// Étends les styles des éléments de formulaire
// -----------------------------------------------------------------------------

.required {
  color: $red;
}

.form-control::placeholder,
.form-floating > label {
  font-weight: $font-weight-light;
}

//
// Formulaire de recherche
//

.input-group--search {
  flex-wrap: nowrap;
  width: auto;

  &:focus-within {
    .form-control {
      width: 220px;
    }
  }

  .btn,
  .form-control {
    border-radius: 0;
  }

  .btn {
    display: inline-flex;
    align-items: center;
    padding: 0.25rem 0.375rem;
    color: $red;
    border: 0;

    &:focus {
      box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width
        rgba($red, $input-btn-focus-color-opacity);
    }

    .icon {
      font-size: 1.375rem;
    }
  }

  .form-control {
    width: 120px;
    text-overflow: ellipsis;
    border: 0;
    border-bottom: 1px solid $red;
    transition: $input-transition, width 0.15s ease-in-out;

    &:focus {
      box-shadow: none;
    }
  }
}
